var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v(" An Executor is a person or professional service provider you appoint in your Will to administer your estate after your death. ")]),_c('faqs',{staticClass:"mb-4",attrs:{"faqs":['executorDuties', 'executorOverseas']}}),_c('div',{staticClass:"d-flex align-center py-2"},[_c('h3',[_vm._v("Your Executors")]),_c('v-spacer')],1),(!_vm.step.isStepComplete)?_c('div',{staticClass:"text-center"},[_c('v-card',{staticClass:"px-4 pb-4",attrs:{"flat":"","color":"bg-grey-10"}},[_c('div',{staticClass:"text-no-result"},[_vm._v(" You must select at least one Executor to continue ")]),_c('v-btn',{staticClass:"bg-white text-capitalize",attrs:{"text":"","x-large":""},on:{"click":function($event){return _vm.open('executors-get-started')}}},[_vm._v("Select your Executors"),_c('v-icon',{attrs:{"right":"","color":"primary","size":"28"}},[_vm._v("mdi-chevron-right")])],1)],1)],1):_c('div',[_c('section-item',{staticClass:"mb-1",on:{"edit":function($event){return _vm.open('executors-get-started')}}},[_c('div',{staticClass:"text-label mb-2"},[_vm._v("Primary Executors")]),(_vm.will_executors.value.primary.length)?_c('ul',{staticClass:"mt-2"},_vm._l((_vm.will_executors.value.primary),function(item){return _c('li',{key:item,staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.executors[item].name)+" "),(
              item !== 'ausUnity' &&
              (!_vm.executors[item].address ||
                !_vm.executors[item].address.trim().length)
            )?_c('chip-required',{attrs:{"text":"Address required"},on:{"click":() => {
                _vm.id = item
                _vm.openEdit = true
              }}}):_vm._e()],1)}),0):_c('div',[_c('chip-required'),_c('div',{staticClass:"text-no-result text-left"},[_vm._v(" Click 'Edit' on the right to add at least 1 primary Executor ")])],1)]),(
        _vm.will_executors.value.primary &&
        !_vm.will_executors.value.primary.includes('ausUnity')
      )?_c('section-item',{on:{"edit":function($event){return _vm.open('executors-backup')}}},[_c('div',{staticClass:"text-label mb-2"},[_vm._v("Backup Executors")]),(_vm.will_executors.value.backup.length)?_c('ol',_vm._l((_vm.will_executors.value.backup),function(item){return _c('li',{key:item,staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.executors[item].name)+" "),(
              item !== 'ausUnity' &&
              (!_vm.executors[item].address ||
                !_vm.executors[item].address.trim().length)
            )?_c('chip-required',{attrs:{"text":"Address required"},on:{"click":() => {
                _vm.id = item
                _vm.openEdit = true
              }}}):_vm._e()],1)}),0):_c('div',{staticClass:"text-no-result text-left"},[_vm._v(" No backup Executors selected ")])]):_vm._e()],1),_c('drawer',{attrs:{"title":"Edit Person","open":_vm.openEdit},on:{"update:open":function($event){_vm.openEdit=$event}}},[_c('div',{staticClass:"pa-4"},[(_vm.openEdit)?_c('form-person',{attrs:{"id":_vm.id,"editable":{ address: true }},on:{"close":() => {
            _vm.openEdit = false
            _vm.id = null
          },"next":() => {
            _vm.openEdit = false
            _vm.id = null
          }}}):_vm._e()],1)]),_c('dialog-full',{attrs:{"open":_vm.dialogOpen,"headline":"Step 4","title":"Executors","path":"/will/executors"},on:{"update:open":function($event){_vm.dialogOpen=$event}}},[(_vm.openForm)?_c(`will-form-${_vm.openForm}`,{tag:"component",on:{"close":_vm.close,"next":_vm.close}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }