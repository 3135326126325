var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('step-progress',{staticClass:"mb-6",attrs:{"current":_vm.window,"total":3}}),_c('div',{staticClass:"divider mb-6 opacity-80"}),_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{attrs:{"value":1}},[_c('h2',{staticClass:"mb-4"},[_vm._v("Who would you like to leave this gift to?")]),_c('p',[_vm._v(" You can select a person or a charity to give a gift to. Once selected, click the Next button to continue. ")]),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(_vm.localFormatted.recipient)?_c('div',{key:_vm.localFormatted.recipient},[_c('item-person',{staticClass:"mb-4",attrs:{"id":_vm.localFormatted.recipient}}),_c('btn-add-large',{attrs:{"label":"Change Recipient","icon":"mdi-sync","disabled":_vm.disable || _vm.processing},on:{"click":function($event){return _vm.removeRecipient()}}}),(_vm.showCharityOptOut)?_c('charity-share-data',{attrs:{"value":_vm.shareData},on:{"update:value":function($event){_vm.shareData=$event}}}):_vm._e()],1):_c('div',{key:"select"},[_c('btn-add-large',{attrs:{"label":"Add Person","disabled":_vm.disable || _vm.processing},on:{"click":function($event){_vm.openDrawerPerson = true}}}),(_vm.referrer_beneficiary_doc)?[_c('btn-add-large',{staticClass:"mt-2",attrs:{"label":`Add ${_vm.referrer_beneficiary_doc.name}`,"subtitle":"Featured Charity","img":_vm.referrer_beneficiary_doc.logo
                    ? _vm.referrer_beneficiary_doc.logo.icon
                    : null,"disabled":_vm.disable || _vm.processing},on:{"click":function($event){return _vm.addCharity(_vm.referrer_beneficiary_doc)}}}),_c('btn-add-large',{staticClass:"mt-2",attrs:{"label":"Add Charity","disabled":_vm.disable || _vm.processing},on:{"click":function($event){_vm.openDrawerCharity = true}}})]:[_c('charities-featured',{staticClass:"mt-4",on:{"add":_vm.addRecipient},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"bg-grey",attrs:{"text":""},on:{"click":function($event){_vm.openDrawerCharity = true}}},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-magnify")]),_vm._v(" View more ")],1)],1)]},proxy:true}],null,false,4255577956)})]],2)]),_c('v-input',{attrs:{"error-messages":_vm.showErrors.recipient && _vm.errorMessages.recipient
              ? _vm.errorMessages.recipient
              : null}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":function($event){_vm.isValidRecipient
                ? _vm.nextStep()
                : _vm.showErrorsSwitch(true, 'recipient')}}},'v-btn',_vm.isValidRecipient ? _vm.btnActive : _vm.btnInactive,false),[_vm._v("Next")])],1)],1),_c('v-window-item',{attrs:{"value":2}},[_c('h2',{staticClass:"mb-4 line-height-md"},[_vm._v(" What type of gift would you like to leave "+_vm._s(_vm.recipientName)+"? ")]),_vm._l((_vm.giftTypeOptions),function(item,index){return _c('item-select',{key:item.key,class:{ 'mb-4': index + 1 < _vm.giftTypeOptions.length },attrs:{"value":item.key === _vm.localFormatted.giftType,"title":item.label,"text":item.text},on:{"select":function($event){_vm.local.giftType = item.key}}})}),_c('v-input',{attrs:{"error-messages":_vm.showErrors.giftType && _vm.errorMessages.giftType
              ? _vm.errorMessages.giftType
              : null}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":function($event){return _vm.prevStep()}}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v("Back")],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":function($event){_vm.isValidType ? _vm.nextStep() : _vm.showErrorsSwitch(true, 'giftType')}}},'v-btn',_vm.isValidType ? _vm.btnActive : _vm.btnInactive,false),[_vm._v("Next")])],1)],2),_c('v-window-item',{attrs:{"value":3}},[(_vm.localFormatted.giftType === 'money')?_c('div',[_c('h2',{staticClass:"mb-4 line-height-md"},[_vm._v(" How much would you like to gift "+_vm._s(_vm.recipientName)+"? ")]),_c('p',[_vm._v(" Enter the amount you would like to gift in Australian Dollars (AUD). ")]),_c('money-field',{attrs:{"label":"Amount (AUD)","error-messages":_vm.showErrors.itemMoney && _vm.errorMessages.itemMoney
                ? _vm.errorMessages.itemMoney
                : null},on:{"blur":function($event){return _vm.showErrorsSwitch(true, 'itemMoney')},"keydown":function($event){return _vm.showErrorsSwitch(true, 'itemMoney')}},model:{value:(_vm.local.itemMoney),callback:function ($$v) {_vm.$set(_vm.local, "itemMoney", $$v)},expression:"local.itemMoney"}})],1):_vm._e(),(_vm.localFormatted.giftType === 'possession')?_c('div',[_c('h2',{staticClass:"mb-4 line-height-md"},[_vm._v(" What would you like to gift "+_vm._s(_vm.recipientName)+"? ")]),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('strong',[_vm._v("Note:")]),_vm._v(" Be specific when describing the item, so that it’s clear to the Executor and the beneficiary exactly what the gift is. Avoid leaving any messages here. ")]),_c('div',{staticClass:"mb-2"},[_vm._v(" I would like to leave "+_vm._s(_vm.recipientName)+" my... ")]),_c('v-text-field',_vm._b({ref:"itemPossession",attrs:{"placeholder":"Enter gift here","error-messages":_vm.showErrors.itemPossession && _vm.errorMessages.itemPossession
                ? _vm.errorMessages.itemPossession
                : null},on:{"blur":function($event){return _vm.showErrorsSwitch(true, 'itemPossession')},"input":function($event){return _vm.showErrorsSwitch(true, 'itemPossession')}},model:{value:(_vm.local.itemPossession),callback:function ($$v) {_vm.$set(_vm.local, "itemPossession", $$v)},expression:"local.itemPossession"}},'v-text-field',_vm.textField,false))],1):_vm._e(),(_vm.messageExists)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Leave a message")]),_c('v-textarea',_vm._b({attrs:{"placeholder":"Enter message","error-messages":_vm.showErrors.message && _vm.errorMessages.message
                ? _vm.errorMessages.message
                : null},model:{value:(_vm.local.message),callback:function ($$v) {_vm.$set(_vm.local, "message", $$v)},expression:"local.message"}},'v-textarea',_vm.textAreaField,false))],1):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":function($event){return _vm.prevStep()}}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v("Back")],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v("Save")])],1)])],1),_c('drawer',{attrs:{"open":_vm.openDrawerPerson,"title":"Add Recipient"},on:{"update:open":function($event){_vm.openDrawerPerson=$event}}},[(_vm.openDrawerPerson)?_c('drawer-person-select',{attrs:{"exclude":[_vm.localFormatted.recipient]},on:{"select":_vm.addRecipient}}):_vm._e()],1),_c('charity-select',{attrs:{"open":_vm.openDrawerCharity,"title":"Select Charity"},on:{"update:open":function($event){_vm.openDrawerCharity=$event},"add":_vm.addRecipient}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }