var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},_vm._l((_vm.windows),function(item,index){return _c('v-window-item',{key:item,attrs:{"value":index + 1}},[(item === 'primary' && _vm.window === 1)?_c('executors-primary',{attrs:{"submitLabel":"Save","closeLabel":index === 0 ? 'Close' : 'Back'},on:{"close":() => {
            index === 0 ? _vm.close() : _vm.prevStep()
          },"next":() => {
            index + 1 === _vm.windows.length ? _vm.close() : _vm.nextStep()
          }}}):_vm._e(),(item === 'backup' && _vm.window === 2)?_c('executors-backup',{attrs:{"submitLabel":"Save","closeLabel":index === 0 ? 'Close' : 'Back'},on:{"close":() => {
            index === 0 ? _vm.close() : _vm.prevStep()
          },"next":() => {
            index + 1 === _vm.windows.length ? _vm.close() : _vm.nextStep()
          }}}):_vm._e()],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }