<template>
  <div>
    <page-will-summary />
  </div>
</template>

<script>
import PageWillSummary from './will/Summary'
export default {
  name: 'PageWill',
  components: { PageWillSummary },
  data: () => ({}),
}
</script>

<style lang="scss"></style>
